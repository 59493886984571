import React from 'react';

const ScrollbarStyles: React.FC = () => (
  <style>
    {`
      /* Webkit browsers like Chrome, Safari */
      ::-webkit-scrollbar {
        width: 8px;
      }

      ::-webkit-scrollbar-track {
        background: var(--scrollbar-track, #f1f1f1);
      }

      ::-webkit-scrollbar-thumb {
        background: var(--scrollbar-thumb, #888);
        border-radius: 4px;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: var(--scrollbar-thumb-hover, #555);
      }

      /* Firefox */
      * {
        scrollbar-width: thin;
        scrollbar-color: var(--scrollbar-thumb, #888) var(--scrollbar-track, #f1f1f1);
      }

      /* For dark mode */
      .dark {
        --scrollbar-track: #2d3748;
        --scrollbar-thumb: #4a5568;
        --scrollbar-thumb-hover: #718096;
      }
    `}
  </style>
);

export default ScrollbarStyles;