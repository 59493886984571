import React, { lazy, Suspense } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ThemeProvider } from './context/ThemeContext';
import Header from './components/Header';
import Footer from './components/Footer';
import NotFound from './components/NotFound';
import ScrollbarStyles from './components/ScrollbarStyles';
// import LoadingSpinner from './components/LoadingSpinner';

const Home = lazy(() => import('./components/Home'));
const About = lazy(() => import('./components/About'));
const Skills = lazy(() => import('./components/Skills'));
const Experience = lazy(() => import('./components/Experience'));
const Projects = lazy(() => import('./components/Projects'));
const Education = lazy(() => import('./components/Education'));
const Contact = lazy(() => import('./components/Contact'));
const Resume = lazy(() => import('./components/Resume'));

const MainContent: React.FC = () => {
  const location = useLocation();
  const is404Page = location.pathname !== '/' && !['about', 'skills', 'experience', 'projects', 'education', 'contact', 'resume'].some(path => location.pathname.includes(path));

  return (
    <main className={`flex-grow ${is404Page ? 'flex items-center justify-center' : ''}`}>
      {/* <Suspense fallback={<LoadingSpinner />}> */}
      <Suspense>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/skills" element={<Skills />} />
          <Route path="/experience" element={<Experience />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/education" element={<Education />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </main>
  );
};

function App() {
  const location = useLocation();
  const is404Page = location.pathname !== '/' && !['about', 'skills', 'experience', 'projects', 'education', 'contact', 'resume'].some(path => location.pathname.includes(path));

  return (
    <ThemeProvider>
      <ScrollbarStyles />
      <div className="flex flex-col min-h-screen bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100 transition-colors duration-200">
        {!is404Page && <Header />}
        <MainContent />
        {!is404Page && <Footer />}
      </div>
    </ThemeProvider>
  );
}

export default App;